//Barvy
.bg-dark-purple {
  background-color: $color-dark-purple;
}

.bg-light-blue {
  background-color: $color-light-blue;
}

.bg-dark-red {
  background-color: $color-dark-red;
}

.bg-orange {
  background-color: $color-orange;
}

.bg-dark-green {
  background-color: $color-dark-green;
}

.bg-light-green {
  background-color: $color-light-green;
}

.bg-light-red {
  background-color: $color-light-red;
}

.bg-dark-blue {
  background-color: $color-dark-blue;
}

.fg-dark-purple {
  color: $color-dark-purple;
}

.fg-light-blue {
  color: $color-light-blue;
}

.fg-dark-red {
  color: $color-dark-red;
}

.fg-orange {
  color: $color-orange;
}

.fg-dark-green {
  color: $color-dark-green;
}

.fg-light-green {
  color: $color-light-green;
}

.fg-light-red {
  color: $color-light-red;
}

.fg-dark-blue {
  color: $color-dark-blue;
}

.fg-ping {
  color: $color-pink;
}

.fg-light-purple {
  color: $color-light-purple;
}


.btn {
  display: flex;
  align-items: center;
  font-family: $font-raleway;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: normal;
  background-color: #fff;
  box-shadow: $btn-box-shadow;
  &:active,
  &.active {
    background-image: none;
    @include box-shadow($btn-active-box-shadow);
  }
}

.btn-inline {
  display: inline-flex;
}

.btn-slider-topic {
  height: 68px;
  text-align: left;
  margin: 0.8%;
  position: relative;
  .category-title {
    margin-left: 55px;
  }
  [class^="icon-"], [class*=" icon-"] {
    position: absolute;
    margin-left: -9px;
    font-size: 45px;
    border-radius: 30px;
    padding: 5px;
  }
}

.widget-sidebar-menu {
  .sidebar-top-buttons {
    .btn-inline {
      height: 52px;
      width: 47%;
      &.btn-dark-purple {
        margin-right: 4%;
      }
      .icon-dum, .icon-hvezda {
        font-size: 30px;
        margin-right: 10px;
      }
      &:not(.btn-dark-purple) {
        background-color: $background-body;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
  .sidebar-buttons {
    margin-top: 35px;
    .btn-slider-topic {
      height: 52px;
      margin: 0 0 10px 0;
      background-color: $background-body;
      [class^="icon-"], [class*=" icon-"] {
        margin-left: -13px;
        font-size: 36px;
      }
      @each $btn-color, $color in
                      (dark-purple, $color-dark-purple),
                      (light-blue, $color-light-blue),
                      (dark-red, $color-dark-red),
                      (orange, $color-orange),
                      (dark-green, $color-dark-green),
                      (light-green, $color-light-green),
                      (light-red, $color-light-red),
                      (dark-blue, $color-dark-blue),
                      (pink, $color-pink),
                      (light-purple, $color-light-purple) {
        &.btn-#{$btn-color} {
          &.active,
          &:active {
            background-color: $color;
          }
        }
      }
    }
  }
}

@each $btn-color, $color in
                (dark-purple, $color-dark-purple),
                (light-blue, $color-light-blue),
                (dark-red, $color-dark-red),
                (orange, $color-orange),
                (dark-green, $color-dark-green),
                (light-green, $color-light-green),
                (light-red, $color-light-red),
                (dark-blue, $color-dark-blue),
                (pink, $color-pink),
                (light-purple, $color-light-purple) {
  .btn-#{$btn-color} {
    color: $color;
    [class^="icon-"], [class*=" icon-"] {
      background-color: $color;
      color: white;
    }
    &.active,
    &:active {
      color: white;
      background-color: $color;
    }
    &.hover,
    &:hover {
      color: $color;
      border-color: $color;
      &.active,
      &:active {
        color: white;
        background-color: darken($color, 5%);
      }
    }
  }
}

.btn-m {
  font-size: 15px;
  height: 46px;
  padding-top: 0;
  padding-bottom: 0;
}

.btn-strong {
  padding: 1rem 3rem;
  font-family: $font-raleway;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
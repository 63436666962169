@import "slick-theme.scss";

/***************************************** */
:root {
  font-size: 16px;
}

/***************************************** */

body {
  background-color: $background-partners;
}

body.home {
  background-color: $background-body;
}

p {
  //margin: 0;
  padding: 0;
  font-family: $font-raleway;
  //font-size: 16px;
}

.slide-content h2 {
  font-family: $font-titan;
}

img {
  max-width: 100%;
  height: auto;
}

//Navigace
#navbar {
  position: absolute;
  width: 100%;
  margin: 0 auto;
  top: 0;

  .navbar {
    z-index: 1;
  }

  //Logo
  .navbar-brand {
    width: 100%;
    background-image: url("../images/logo.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(sm) {
      height: 45px;
    }

    @include media-breakpoint-up(sm) {
      height: 90px;
    }

    @include media-breakpoint-up(lg) {
      width: 336px;
      max-width: 336px;
      background-size: 100%;
    }
  }

  .btn {
    color: #6315c3;
    height: 50px;
    font-size: 14px;
    padding: 0 2rem;

    i, span {
      font-size: 26px;
    }
  }

  .navbar-text {
    padding: 0 2rem;
    margin-top: 1rem;
    p {
      font-weight: 700;
      color: #fff;
    }

    @include media-breakpoint-up(lg) {
      p {
        text-align: right;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-text {
      margin: 0;

      p:nth-child(1) {
        text-align: center;
        font-size: 26px;
      }

      p:nth-child(2) {
        text-align: center;
        font-size: 16px;
      }
    }
    .btn-strong {
      max-width: 263px;
      margin: auto;
    }
  }
}

/***************************************** */

#header {
  .slide {

    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 510px;

    //height: 200px

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .slide-content {
      text-align: center;

      .slide-content-inner {
        display: inline-block;
        text-align: left;
        float: left;

        h2 {
          color: #fff;
          font-size: 30px;
        }

        .btn-default {
          margin: 30px 0;
        }
      }
    }

    @include media-breakpoint-up(md) {
      .slide-content .slide-content-inner h2 {
        font-size: 46px;
      }
    }
  }
}

/***************************************** */
#partners {
  background-color: $background-partners;
  padding: 25px 0;

  h2 {
    font-family: $font-titan;
    color: #909090;
    font-size: 28px;
    padding: 0 15px;
  }

  .slick-slide {
    padding: 20px;
  }

  .slick-dots li button:before {
    font-size: 20px;
  }
}

/***************************************** */
#subscribe {
  background: $background-subsribe url("../images/subscribe-bg.png") no-repeat 60% 60%;
  background-size: contain;
  overflow: hidden;
  padding: 25px 0;

  h2 {
    color: #fff;

    span:nth-child(1) {
      color: #006fa9;
      display: block;
    }
  }

  #email {
    font-style: italic;
    padding: 1.5rem 1.75rem;
  }

  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 24px;
      text-align: center;
    }

    p {
      font-size: 12px;
      margin: 15px;
    }

    .subscribe-image {
      img {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(sm) {
    h2 {
      font-size: 34px;
      line-height: 45px;
      margin-top: 24px;
    }

    p {
      font-size: 14px;
    }

    .subscribe-image {
      img {
        transform: scale(1.5);
        margin: (-40px) 0;
      }
    }

    form {
      margin-top: 30px;
    }
  }
}

@include media-breakpoint-down(md) {
  #subscribe {
    background-image: none;
  }
}

.btn-download {
  height: 70px;
  padding: 0 1rem;
  font-size: 20px;
  font-weight: 900;
  background-color: #006fa9;
  color: #fff !important;

  i {
    font-size: 40px;
    color: #21c3f8;
  }
}

.widget-sidebar-menu #subscribe {
  background-image: url("../images/subscribe-sidebar-bg.png");
  background-position: top center;
  background-size: cover;
  margin-top: 70px;
  h2 {
    font-size: 30px;
    width: 190px;
    line-height: 38px;
    margin-top: 10px;
    .fg-light-blue {
      font-size: 22px;
      width: 320px;
    }
    @include media-breakpoint-down(lg) {
      font-size: 22px;
      .fg-light-blue {
        font-size: 18px;
        width: auto;
      }
    }
    @include media-breakpoint-down(sm) {
      .fg-light-blue {
        width: 100%;
      }
    }
  }
  #email {
    padding: 16px 1.75rem;
  }
  .btn-download {
    font-size: 17px;
    height: 64px;
    margin-bottom: 25px;
    &:hover, &:active, &:focus {
      background-color: $color-red-purple;
      .icon-download {
        color: $color-light-red-purple;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .widget-sidebar-menu #subscribe .container {
    padding: 0;
  }
}

/***************************************** */

#footer {
  padding: 20px 0;
  font-size: 16px;

  .social {
    a {
      font-size: 40px;
      color: #6d6e71;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .copyright, .author {
    display: inline-block;
    margin-top: 5px;
  }

  @include media-breakpoint-down(sm) {
    .social {
      text-align: center;
    }

    .copyright, .author {
      display: block;
      text-align: center;
    }
  }

  .author-logo {
    height: 33px;
    width: 104px;
    display: inline-block;
    vertical-align: middle;
    transition-duration: .5s;
    margin: 0 5px;
    background-image: url("../images/rtsoft.png");
    background-position: center 0;
    background-repeat: no-repeat;

    &:hover {
      background-position: center -33px;
    }
  }
}

/** frontpage **/
#header {
  width: 100%;
  margin-bottom: 40px;

  @include media-breakpoint-down(sm) {
    .slide-content-inner {
      margin-bottom: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    .slide-content-inner {
      margin-bottom: 46px;
      margin-left: 180px;
    }

    .slide-content .btn {
      font-size: 15px;
      margin: 15px 0;
      height: 60px;
      padding: 0 2rem;
    }
  }

  @include media-breakpoint-up(md) {
    .slide-content-inner {
      margin-left: 280px;
    }
  }

  @include media-breakpoint-up(lg) {
    .slide-content-inner {
      margin-bottom: 52px;
      margin-left: 50%;
    }
  }

  /***************************************** */

  //Postran├ş tla─Ź├ştka slideru
  .slick-prev:before, .slick-next:before {
    font-size: 45px;
    color: #333;
  }
}

/***************************************** */

#buttons {
  .slider-buttons {
    text-align: center;
    .btn-slider-topic {
      margin: 8px;
    }
  }
}

@include media-breakpoint-up(sm) {
  #buttons {
    .slider-buttons {
      .btn-slider-topic {
        margin: 4px;
        display: inline-flex;
        width: 48%;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  #buttons {
    .slider-buttons {
      .btn-slider-topic {
        width: 31%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  #buttons {
    .slider-buttons {
      .btn-slider-topic {
        width: 18%;
      }
    }
  }
}

#buttons {
  margin-top: -15px;
  margin-bottom: 25px;
}
#about-project {
  margin-top: 25px;
  margin-bottom: 25px;
}
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
$btn-transition: all .1s ease-in-out;
$enable-rounded: true;
$border-radius: 5rem;
//Barvy
$color-dark-purple: #7C32D7;
$color-light-blue: #0298CA;
$color-dark-red: #C81700;
$color-orange: #FF6C00;
$color-dark-green: #419300;
$color-light-green: #A0CD02;
$color-light-red: #E13000;
$color-dark-blue: #0E48CA;
$color-pink: #DD1076;
$color-light-purple: #AE2DC6;
$color-red-purple: #CB2129;
$color-light-red-purple: #FC676E;

$brand-primary: $color-dark-purple;

/***************************************** */

$background-body: #EDEDED;
$background-partners: #fff;
$background-subsribe: #6fd4f5;

/***************************************** */
$btn-box-shadow: 0 1px 4px rgba(63, 65, 56, .1);
$btn-focus-box-shadow: $btn-box-shadow;
$btn-active-box-shadow: none;

$font-titan: "Titan One", cursive;
$font-raleway: "Raleway", sans-serif;

$slick-font-path: "../fonts/";

$headings-font-family: $font-raleway;